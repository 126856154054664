import Portfolio from './pages/portfolio';


function App() {
  return (
    <Portfolio></Portfolio>
  );
}

export default App;
